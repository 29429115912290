  /* Hero.css */

  .text-black {
    color: black;
    transition: color 0.3s ease-in-out;
  }

  .bg-white {
    background-color: white;
    transition: background-color 0.3s ease-in-out;
  }

  .text-[#00df9a] {
    color: #00df9a;
    transition: color 0.3s ease-in-out;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .text-center {
    text-align: center;
  }

  .text-justify {
    text-align: justify;
  }

  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .pt-16 {
    padding-top: 4rem;
  }

  .pb-2 {
    padding-bottom: 1rem;
  }

  .p-6 {
    padding: 1.5rem;
  }

  .mb-4 {
    margin-bottom: 2.5rem;
  }

  .font-bold {
    font-weight: bold;
    animation: pulse 1s infinite alternate;
  }

 

  /* Mobile styles */

  .text-4xl {
    font-size: 2rem;
    animation: fadeIn 2s;
  }

  .text-xl {
    font-size: 1.25rem;
    animation: slideInFromLeft 1s;
  }

  @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }

  @keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
  }

  /* Tablet styles (e.g. iPad) */

  @media (min-width: 768px) and (max-width: 1024px) {
    .text-5xl {
        font-size: 4rem;
        animation: bounce 1s infinite;
    }

    .text-xl {
        font-size: 1.5rem;
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
  }

  /* Laptop/Desktop styles */

  @media (min-width: 1025px) {
    .text-5xl {
        font-size: 5rem;
        animation: rotate 5s infinite;
    }

    .text-xl {
        font-size: 1.75rem;
    }
  }


