/* Universal Styles */
body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
}

.about-container {
    width: 100%;
    background-color: #f5f5f5; /* Lighter background for a modern look */
    padding: 4rem 1rem;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 8px; /* Rounded corners */
}

.director-image {
    width: 200px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 2rem;
    transition: width 0.3s ease, transform 0.3s ease; /* Added transform for hover effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.director-image:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

.mb-2 {
    margin-bottom: .5rem;
}

.about-title {
    color: #007BFF; /* Changed to a modern blue */
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle text shadow */
}

.text-justify {
    text-align: justify;
}

.pb-4 {
    padding-bottom: .4rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

/* Mobile devices (default styles) */
/* Nothing additional is needed for mobile as the defaults work for it */

/* iPads/Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
    .about-title {
        font-size: 3.5rem;
    }

    .director-image {
        width: 250px;
    }
}

/* Laptops/Desktops */
@media (min-width: 1025px) {
    .about-title {
        font-size: 4rem;
    }

    .director-image {
        width: 300px;
    }
}
