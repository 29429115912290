.indirect-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #e0e0e0;
    padding: 16px;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.indirect-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.indirect-image {
    max-width: 660px;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden;
}

.indirect-title {
    color: #00df9a;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
    margin-bottom: 12px;
}

.indirect-content h2,
.indirect-content h3,
.indirect-content h4 {
    color: #00df9a;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    margin: 12px 0;
}

.indirect-content ul {
    list-style-type: disc;
    padding-left: 24px;
}

/* Mobile devices */
@media screen and (max-width: 768px) {
    .indirect-content h2,
    .indirect-content h3,
    .indirect-content h4 {
        font-size: 1.5em;
    }
}

/* iPads & tablets */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .indirect-image {
        width: 80%;
    }

    .indirect-content {
        padding: 0 10%;
    }
}

/* Laptops and above */
@media screen and (min-width: 1025px) {
    .indirect-image {
        width: 90%;
    }

    .indirect-content {
        padding: 0 15%;
    }
}
