/* Service.css */

/* Base styles for the main grid container */
.max-w-[700px] {
    max-width: 700px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}

.service-container{
    color: black ;
    font-size: 4rem;
     font: bold;
     text-align: center;
    font-weight: bold;
}

/* Styles for the individual service link */
.flex.flex-col.justify-center.mb-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8px;
}

.w-full.mx-auto.my-4 {
    width: 100%;
    margin: 4px auto;
}

/* Heading styles */
.text-[#00df9a].text-xl.font-bold.text-center {
    color: #00df9a;
    font-size: 1rem; /* Approximate value for Tailwind's text-xl */
    font-weight: bold;
    text-align: center;
}

/* If you plan on having a dropdown effect for these links, consider using :hover pseudo-class and visibility or display properties. 
   However, based on the code you've given, it's unclear what dropdown effect you're aiming for. */

/* Mobile styles */
.service-container img {
    max-width: 100%;
    height: auto;
}

.service-grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(1, 1fr);
}

/* For the "Our Services" header */
.service-header {
    font-size: 24px; /* You can adjust this value to your preference */
    font-weight: bold;
    color: black;
    text-align: center;
    padding-bottom: 8px;
}

/* Tablet styles (e.g. iPad) */
@media (min-width: 768px) and (max-width: 1024px) {
    .service-container {
        grid-template-columns: repeat(1, 1fr);
        max-width: 800px;
        margin: 0 auto;
    }
}

/* Laptop/Desktop styles */
@media (min-width: 1025px) {
    .service-container {
        grid-template-columns: repeat(2, 1fr);
        max-width: 1200px;
        margin: 0 auto;
    }
} 