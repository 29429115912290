/* ContactUs.css */

.container-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;  
    width: 100%;
    background-color: #f8f9fa; /* Lighter background for a modern look */
    padding: 42px 50px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s ease; /* Transition for hover effect */
}

.container-contact:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
}

.image-section-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px; 
    animation: fadeIn 1s; /* Fade in animation */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.image-round-contact {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    transition: transform 0.3s ease; /* Transition for hover effect */
}

.image-round-contact:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
}

.headline-contact {
    color: #007BFF; /* Changed to a modern blue */
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle text shadow */
}

.description-contact {
    text-align: center;
    max-width: 80%;
    font-size: 1.2rem;
    margin-top: 20px;
    opacity: 0.9; /* Slight transparency for modern feel */
}

/* For tablets/iPads */
@media screen and (max-width: 768px) {
    .container-contact {
        padding: 20px 30px;
    }

    .image-round-contact {
        width: 200px;
        height: 200px;
    }

    .headline-contact {
        font-size: 1.3rem;
    }

    .description-contact {
        font-size: 1rem;
    }
}

/* For mobile phones */
@media screen and (max-width: 480px) {
    .container-contact {
        flex-direction: column;
        padding: 50px 20px;
    }

    .image-section-contact {
        margin-bottom: 20px;
    }

    .image-round-contact {
        width: 150px;
        height: 150px;
    }

    .headline-contact {
        font-size: 1.1rem;
    }

    .description-contact {
        font-size: 0.9rem;
    }
}
