.site-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-wrapper {
    flex: 1;
}

.footer-container {
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    box-sizing: border-box;
    flex-wrap: wrap;
    flex-direction: column;
}

.footer-section {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.contact-title {
    width: 100%;
    font-size: 36px;
    font-weight: bold;
    color: #00df9a;
    margin-bottom: 20px;
}

.contact-details {
    padding: 50px 0px 25px;
    line-height: 1.75;
}

.quick-links-title, .services-title {
    font-weight: semi-bold;
    font-size: 24px;
    color: white;
    margin-bottom: 0px;
    padding: 10px 0; /* Added padding to subheadings */
}

.quick-links-list a, .services-list a, .service-link {
    text-decoration: none;
    color: white;

    padding: 15px 0px 0px;

    display: block;
    font-size: 16px;
}

.quick-links-list a:hover, .services-list a:hover, .service-link:hover {
    color: #555;
}

@media screen and (max-width: 480px) {
    .contact-title, {
        font-size: 24px;
    }
    .quick-links-title, .services-title {
        font-size: 24px;
    }
    .contact-details, .quick-links-list a, .services-list a {
        font-size: 14px;
    }
    .footer-section {
        padding: 15px;
    }
    .quick-links-list a, .services-list a, .service-link {
        padding: 5px 5px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .footer-container {
        flex-wrap: nowrap;
    }
    .footer-section {
        padding: 15px;
    }
}

@media screen and (min-width: 769px) {
    .footer-container {
        flex-direction: row;
        padding: 13px 40px;
    }
    .footer-section {
        width: 40%;
        padding: 10px;
    }
}
