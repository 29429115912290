/* Base styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f3f4f6;
}

/* Main form container */
.w-inc {
  max-width: 1000px;
  margin: 50px auto; /* Added top and bottom margin for better spacing */
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
  transition: transform 0.3s ease; /* Added transition for hover effect */
}

.w-inc:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
}

/* Main heading style */
h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase; /* Making the text uppercase for a modern look */
  letter-spacing: 2px; /* Spacing out the letters */
}

/* Form fields styling */
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  animation: fadeIn 1s; /* Fade in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input,
textarea,
button {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.3s ease, box-shadow 0.3s ease; /* Added box-shadow transition */
}

input:focus,
textarea:focus {
  border-color: #00DF9A;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 223, 154, 0.5); /* Added a subtle glow effect on focus */
}

/* Textarea specific style */
textarea {
  min-height: 100px;
  resize: vertical;
}

/* Submit button style */
button {
  background-color: #00DF9A;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */
}

button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Responsive for smaller devices */
@media screen and (max-width: 768px) {
  form {
      grid-template-columns: 1fr;
  }

  .w-inc {
      padding: 10px;
  }
}

/* Popup styles */
.fixed {
  position: fixed;
}

.rounded {
  border-radius: 8px;
}

.shadow-md {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.z-50 {
  z-index: 50;
}

.p-8 {
  padding: 20px;
}

/* Center aligning utilities */
.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.h-screen {
  height: 100vh;
}
